// FONTS
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap");

// COLOR CONSTANTS

$background-gray: #f0f0f0;

$button-bg: #071414e8;

$ui-primary-color: #001514;

$ui-secondary-color: #9c9c9c;

// DEFAULT UI CONSTANTS

$container-width: 1100px;

// CUSTOM MIXINS

@mixin center-child {
	display: flex;
	justify-content: center;
	align-items: center;
}

@mixin black-bg {
	background: rgb(0, 0, 0);
	background: linear-gradient(
		0deg,
		rgba(0, 0, 0, 1) 65%,
		rgba(0, 0, 0, 0) 100%
	);
	color: rgb(222, 222, 222);
}

@mixin white-bg {
	background: rgb(255, 255, 255);
	background: linear-gradient(
		0deg,
		rgba(255, 255, 255, 1) 65%,
		rgba(255, 255, 255, 0) 100%
	);
	color: rgb(41, 40, 40);
}

// BASIC STYLES RESETS

* {
	box-sizing: border-box;
}
body {
	margin: 0;
	padding: 0;
	font-size: 16px;
	font-family: "Poppins", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: $background-gray;
}

.text-center {
	text-align: center;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.text-bod {
	font-weight: bold;
}

.container {
	width: 100%;
	max-width: $container-width;
	margin: 0 auto;
}

.nofound {
	font-family: "Poppins", sans-serif;
	@include center-child;
	flex-direction: column;
	height: 100vh;

	h1 {
		font-weight: 4em;
		color: red;
	}

	a {
		text-decoration: none;
		padding: 1em;
		background: rgba(3, 3, 3, 0.13);
		color: $ui-primary-color;
		border-radius: 8px;

		&:hover {
			background: $ui-primary-color;
			color: #fff;
		}
	}
}

.custom-row {
	//common styles
}

.custom-row-4 {
	-webkit-column-count: 4;
	-moz-column-count: 4;
	column-count: 4;
}

.custom-row-3 {
	-webkit-column-count: 3;
	-moz-column-count: 3;
	column-count: 3;
}

.custom-row-2 {
	-webkit-column-count: 2;
	-moz-column-count: 2;
	column-count: 2;
}

.custom-row-1 {
	-webkit-column-count: 1;
	-moz-column-count: 1;
	column-count: 1;
}

.row-item {
	-webkit-column-break-inside: avoid;
	page-break-inside: avoid;
	break-inside: avoid;
	will-change: transform;
	&:first-child {
		transform: translateY(-0.5em);
	}
}
