.home{
  padding: 1em;


  &__header{
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .category{
      text-transform: uppercase;
      color: $ui-secondary-color;
      margin: 0;
    }

    .categoryName{
      margin: 0;
      font-size: 2.2em;
    }
  }


  &__cardContainer{
    // background: pink;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

  }
}