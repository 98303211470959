.detailsPage {
	&__goBack {
		padding: 1em 0;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		color: #000;
		cursor: pointer;

		a {
			text-decoration: none;
			color: #000;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 0.5em;
			border-radius: 8px;

			&:hover {
				text-decoration: none;
				background: rgba(14, 14, 14, 0.068);
			}
			&:active {
				background: rgba(14, 14, 14, 0.171);
			}
		}
	}

	&__row {
		width: 100%;
		display: flex;
    flex-wrap: wrap;
    padding: 0 1em;

		.col-left {
      width: 100%;
      
      p{
        color: rgb(84, 83, 83);
      }
		}

		.col-right {
      width: 100%;
      padding: 1em;
		}

		@media only screen and (min-width: 768px) {
			.col-left {
				width: 55%;
			}

			.col-right {
				width: 45%;
			}
		}
  }
  
  .videoContainer{
    display: flex;
    justify-content: center;
    align-items: center;

    iframe{
      width: 100%;
      border-radius: 12px;
    }
  }

  &__bottomText{
    text-align: center;
    font-weight: bold;
    font-size: 1.5em;
    color: lightgray;
    position: relative;
    &::after{
      content: "";
      width: 100%;
      height: 2px;
      background: lightgray;
      position: absolute;
      top: 0;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
    }

    p{
      position: relative;
      z-index: 5;
      background: $background-gray;
      width: fit-content;
      left: 50%;
      transform: translateX(-50%);
      padding: 0 1em;
    }
  }


  // Right column

  .info{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    .header{
      color: gray;
    }
    h1{
      margin: 0;
      font-size: 2.4em;
    }

    .description{
      text-align: right;
      padding: 0;
      margin-top: -1em;
    }

    .starts{
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  .points{
    display: flex;
    justify-content: flex-end;
    align-self: center;
    padding: 1em 0;


    .point{
      background: #000;
      color: #fff;
      font-weight: bold;
      text-align: center;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      margin-left: 1em;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-self: center;
      p{
        font-size: 1.5em;
        margin: 0;
      }
      .name{
        font-size: 13px;
      }
    }
  }



  .fav{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 1em 0;
    svg{
      color: red;
    }

    border-bottom: 2px solid lightgray;
  }


  .contact{
    margin-top: 6em;
    input{
      background: #fff;
      width: 100%;
      height: 100px;
      border: none;
      border-radius: 12px;
    }

    button{
      width: 100%;
      margin: 1em 0;
      color: #fff;
      background: $button-bg;
      padding: 1em;
      font-weight: bold;
    }
  }

}
