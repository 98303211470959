.recipeCard {
	background: rgba(2, 2, 2, 0.061);
	width: 100%;
	max-width: 250px;
	border-radius: 12px;
  position: relative;
  margin: 0.5em;
  height: 400px;
  overflow: hidden;


  &__label{
    background: yellow;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    padding: 0.25em 1.5em;
    font-size: 13px;
    border-bottom-left-radius: 12px;
    color: #fff;
    background: rgba(0, 0, 0, 0.671);
  }

	&__image {
		position: absolute;
		// width: 100%;
    height: 50%;
    width:100%;
		left: 0;
		top: 0;
		z-index: 0;
	}

	&__content {
		position: relative;
		z-index: 5;
    padding: 1em;
    margin: 0;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    position: absolute;
    width: 100%;
    height: 80%;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
		@include black-bg;

		.header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			color: attr("data-color");
			.name {
				h2 {
					margin: 0;
				}

				p {
					margin: 0;
				}

				.clock {
					display: inline-block;
					width: 1em;
					position: relative;
					top: 6px;
				}
			}

			.like {
				width: 30px;
				height: 30px;
				cursor: pointer;

				svg {
					width: 100%;
					height: auto;
				}
			}

			.liked-true {
				color: red;
			}
		}

		.description {
			font-size: 0.75em;
		}
	}

	.white_card {
		@include white-bg;
  }
  
  &:nth-child(even){
    height: 350px;
  }


  .overlay{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: $button-bg;
    z-index: 20;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: opacity 500ms ease;

    .buttonContainer{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      button{
        margin: 0.5em;
        border: 2px solid #fff;
        color: #fff;
        transition: color 500ms ease;


        &:hover{
          color: $button-bg;
          background: #fff;
        }

        a{
          text-decoration: none;
          color: unset;
        }
      }
    }

    &:hover{
      opacity: 1;
    }
  }
}
